<template>
  <div class="account__subcontainer">
    <div
      @click="toggleSubjectList(1)"
      class="account__information-subcontainer account__information-subcontainer--1"
    >
      <div class="account__information-key">
        {{ $t("account_general")["name"]["key"] }}:
      </div>
      <!-- <div class="account__information">
        {{ $store.state.user.firstName }} {{ $store.state.user.lastName }}
        <span
          v-if="!$store.state.user.firstName && !$store.state.user.lastName"
          >{{ $t("account_general.name.default") }}</span
        >
      </div>
      <app-button
        type="button"
        :text="buttonText(1)"
        variation="transparent"
        class="account__change-button"
      ></app-button>
      <div class="account__change-container account__change-container--1">
        <app-input-complex
          :data="firstName"
          @click="$event.stopPropagation()"
          :inputText="$store.state.user.firstName"
          class="account__input account__input--1 account__input--first-name"
        ></app-input-complex>
        <app-input-complex
          :data="lastName"
          @click="$event.stopPropagation()"
          :inputText="$store.state.user.lastName"
          class="account__input account__input--1 account__input--second account__input--last-name"
        ></app-input-complex>
        <app-button
          type="submit"
          :text="$t('account.controls.store')"
          variation="standard"
          @click="
            saveName();
            $event.stopPropagation();
          "
          class="account__confirm-button"
        ></app-button>
      </div>
    </div>
    <div
      @click="deleteAccount"
      class="account__information-subcontainer account__information-subcontainer--2 test2"
    >
      <div class="account__information-key test">
        {{ $t("account_security.delete_account.key") }}
      </div> -->
    </div>
  </div>
</template>

<script>
import { accountSubcontainer } from "@/components/Organisms/AccountSubcontainer/_assets/_accountSubcontainer.script.js";

export default {
  mixins: [accountSubcontainer],
  methods: {
    deleteAccount() {
      let data = {
        text: this.$t("account_security.delete_account.modal_1"),
        action: this.closeModal,
        action2: () => {
          this.changeModal("app-delete-account-modal", "text-alert");
        }
      };
      this.toggleModal(data, "text-alert");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "_assets/accountSubcontainer.style.scss";

.test {
  width: 60rem;
  font-family: $font-primary;
}

.test2 {
  border-bottom: 0 !important;
}
</style>
