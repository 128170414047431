// // import AppButton from "@/components/Atoms/Button/Button.vue";
import AppButton from "@/components/Atoms/Button.vue";
// // // import AppInputComplex from "@/components/Molecules/InputComplex.vue";
// // // import AppInputPassword from "@/components/Molecules/InputPassword.vue";

// // // import firebase from "firebase";
// // // require("firebase/auth");

export const accountSubcontainer = {
  name: "accountSubcontainer",
  components: {
    AppButton
    // // // AppInputComplex,
    // // // AppInputPassword
  },
  data() {
    return {
      changeButtonTextEarlier: false,
      eraseInput: false,
      subjects: ["mathematics", "physics", "psychology"],
      languages: ["de", "en"],
      firstName: {
        id: "field_1",
        label: "account_general.name.first_name",
        mail: "",
        autocomplete: "email"
      },
      lastName: {
        id: "field_2",
        label: "account_general.name.last_name",
        mail: "",
        autocomplete: "email"
      },
      email: {
        id: "field_3",
        label: "account_general.email.new_email",
        mail: "",
        autocomplete: "email"
      },
      password: {
        id: "field_4",
        label: "account_general.email.password",
        mail: "",
        autocomplete: "email"
      },
      passwordOld: {
        id: "field_5",
        label: "account_general.password.old_password",
        mail: "",
        autocomplete: "email"
      },
      passwordNew: {
        id: "field_6",
        label: "account_general.password.new_password",
        mail: "",
        autocomplete: "email"
      }
    };
  },
  computed: {
    subjectListIsOpen() {
      return this.$store.state.subjectListIsOpen;
    },
    bullets() {
      return "•".repeat(this.$store.state.user.bullets);
    },
    languageMenuRequested() {
      return this.$store.state.languageMenuRequested;
    }
  },
  methods: {
    /* buttonText(i) {
      return this.$store.state.subjectListIsOpen === i
        ? this.$t("account"["controls"]["close"])
        : this.$t("account"["controls"]["change"]);
    }, */
    buttonText() {
      return this.$t("account")["controls"]["change"];
    },
    toggleSubjectList(list) {
      if (this.$store.state.subjectListIsOpen === list) {
        this.$store.state.subjectListIsOpen = false;
      } else {
        this.$store.state.subjectListIsOpen = list;
      }

      let container;
      let accountContainer;
      let numberOfChangeContainers = document.querySelectorAll(
        ".account__change-container"
      ).length;
      for (let i = 1; i <= numberOfChangeContainers; i++) {
        container = document.querySelector(
          ".account__information-subcontainer--" + i
        );
        accountContainer = document.querySelector(
          ".account__change-container--" + i
        );
        let containerHeight = this.height(container);
        let accountContainerHeight = this.height(accountContainer);
        container.style.maxHeight =
          this.$store.state.subjectListIsOpen === i
            ? containerHeight + accountContainerHeight + "px"
            : "6.65rem";
      }

      // document.querySelector('.account__input--email .input').value = ''
      // alert(document.querySelector('.account__input--email .input').value)
      setTimeout(() => {
        this.eraseInput = true;
        setTimeout(() => {
          this.eraseInput = false;
        }, 20);
      }, 500);
    }
    /* saveName() {
      /* let user = firebase.auth().currentUser
      if (!user) {
        this.toggleAlert('Kein User angemeldet!')
      } else {
        this.toggleAlert('User ist angemeldet!')
      }
      return */

    /* let firstName = document
        .querySelector(".account__input--first-name .input")
        .value.trim();
      let lastName = document
        .querySelector(".account__input--last-name .input")
        .value.trim();

      let data = {
        action: this.toggleSubjectList,
        delay: 500
      };

      if (
        (!this.$store.state.user.firstName ||
          this.$store.state.user.firstName.length === 0) &&
        (!this.$store.state.user.lastName ||
          this.$store.state.user.lastName.length === 0)
      ) {
        if (firstName === "" && lastName === "") {
          this.toggleAlert(this.$t("account_general.name.alert_enter_name"));
        } else {
          data.message = this.$t("account_general.name.alert_name_entered");
          this.toggleAlert(data);
        }
      } else if (
        firstName === this.$store.state.user.firstName &&
        lastName === this.$store.state.user.lastName
      ) {
        this.toggleSubjectList();
      } else if (firstName === "" && lastName === "") {
        data.message = this.$t("account_general.name.alert_name_deleted");
        this.toggleAlert(data);
      } else if (
        firstName === "" &&
        lastName === this.$store.state.user.lastName
      ) {
        data.message = this.$t("account_general.name.alert_first_name_deleted");
        this.toggleAlert(data);
      } else if (
        firstName === this.$store.state.user.firstName &&
        lastName === ""
      ) {
        data.message = this.$t("account_general.name.alert_last_name_deleted");
        this.toggleAlert(data);
      } else {
        data.message = this.$t("account_general.name.alert_name_changed");
        this.toggleAlert(data);
      }

      if (firstName !== this.$store.state.user.firstName) {
        let user = firebase.auth().currentUser;
        user.updateProfile({
          displayName: firstName
        });
      }
      this.$store.dispatch("saveUserData", {
        firstName: firstName,
        lastName: lastName
      });
    },
    saveEmail() {
      let user = firebase.auth().currentUser;
      let email = document
        .querySelector(".account__input--email .input")
        .value.trim();
      let password = document.querySelector(".account__input--password .input")
        .value;

      if (email === "" && password === "") {
        this.toggleAlert(
          this.$t("account_general.email.alert_no_email_no_password")
        );
        return;
      } else if (email === user.email && password === "") {
        this.toggleAlert(
          this.$t("account_general.email.alert_old_email_no_password")
        );
        return;
      } else if (email === user.email) {
        this.toggleAlert(this.$t("account_general.email.alert_old_email"));
        return;
      } else if (email === "") {
        this.toggleAlert(this.$t("account_general.email.alert_no_email"));
        return;
      } else if (password === "") {
        this.toggleAlert(this.$t("account_general.email.alert_no_password"));
        return;
      }

      firebase.auth().languageCode = this.$i18n.locale;
      let credential = firebase.auth.EmailAuthProvider.credential(
        user.email,
        password
      );

      user
        .reauthenticateWithCredential(credential)
        .then(() => {
          user
            .updateEmail(email)
            .then(() => {
              this.$set(this.$store.state.user, "email", email);

              let verificationCode = " " + user.photoURL.trim();

              user
                .updateProfile({
                  photoURL: verificationCode
                })
                .catch(error => {
                  alert(error);
                });
              let data = {
                message: this.$t("account_general.email.alert_email_changed"),
                action: this.toggleSubjectList,
                delay: 500
              };
              this.toggleAlert(data);
            })
            .catch(error => {
              if (error.code === "auth/invalid-email") {
                this.toggleAlert(
                  this.$t("account_general.email.alert_email_not_valid")
                );
              } else if (error.code === "auth/email-already-in-use") {
                this.toggleAlert(
                  this.$t("account_general.email.alert_email_already_in_use")
                );
              }
            });
        })
        .catch(error => {
          if (error.code === "auth/wrong-password") {
            this.toggleAlert(
              this.$t("account_general.email.alert_wrong_password")
            );
          }
        });
    },
    savePassword() {
      let user = firebase.auth().currentUser;

      let oldPassword = document.querySelector(
        ".account__input--reset-old-password .input"
      ).value;
      let newPassword = document.querySelector(
        ".account__input--reset-new-password .input"
      ).value;

      if (oldPassword === "" && newPassword === "") {
        this.toggleAlert(
          this.$t(
            "account_general.password.alert_no_old_password_no_new_password"
          )
        );
        return;
      } else if (oldPassword === "") {
        this.toggleAlert(
          this.$t("account_general.password.alert_no_old_password")
        );
        return;
      } else if (oldPassword.length < 6) {
        this.toggleAlert(
          this.$t("account_general.password.alert_wrong_old_password")
        );
        return;
      } else if (newPassword === "") {
        this.toggleAlert(
          this.$t("account_general.password.alert_no_new_password")
        );
        return;
      } else if (newPassword.length < 6) {
        this.toggleAlert(
          this.$t("account_general.password.alert_new_password_too_short")
        );
        return;
      }

      firebase.auth().languageCode = this.$i18n.locale;
      let credential = firebase.auth.EmailAuthProvider.credential(
        user.email,
        oldPassword
      );

      user
        .reauthenticateWithCredential(credential)
        .then(() => {
          var user = firebase.auth().currentUser;

          user
            .updatePassword(newPassword)
            .then(() => {
              let data = {
                message: this.$t(
                  "account_general.password.alert_password_changed"
                ),
                action: this.toggleSubjectList,
                delay: 500
              };
              this.toggleAlert(data);
              let bullets = newPassword.length;
              this.$store.dispatch("saveUserData", { bullets: bullets });
            })
            .catch(error => {
              alert(error);
            });
        })
        .catch(error => {
          if (error.code === "auth/wrong-password") {
            this.toggleAlert(
              this.$t("account_general.password.alert_wrong_old_password")
            );
          }
        });
    },
    selectSubject(subjectIndex) {
      this.$store.state.user.subject = this.subjects[subjectIndex];

      let userId = this.$store.state.userId;
      var updates = {};
      updates["/user/" + userId + "/subject"] = this.subjects[subjectIndex];
      firebase
        .database()
        .ref()
        .update(updates, function(error) {
          if (error) {
            alert(error);
          }
        });
      let data = {
        message: this.$t("account_general.subject.alert_subject_changed"),
        action: this.toggleSubjectList,
        delay: 500
      };
      this.toggleAlert(data);
    },
    selectLanguage(languageIndex) {
      let user = firebase.auth().currentUser;
      let firstCharacter = user.photoURL[0] === " " ? " " : "";
      user
        .updateProfile({
          photoURL: firstCharacter + this.languages[languageIndex]
        })
        .catch(error => {
          alert(error);
        });

      this.$i18n.locale = this.languages[languageIndex];
      localStorage.setItem("language", this.languages[languageIndex]);
      this.$store.state.user.language = this.languages[languageIndex];

      let data = {
        message: this.$t("account_settings.language.alert_language_changed"),
        action: this.toggleSubjectList,
        delay: 500
      };
      this.toggleAlert(data);
    } */
  },
  mounted() {
    this.$store.state.subjectListIsOpen = false;
  },
  watch: {
    subjectListIsOpen(to) {
      if (to >= 100) {
        let numberOfChangeContainers = document.querySelectorAll(
          ".account__change-container"
        ).length;
        for (let i = 1; i <= numberOfChangeContainers; i++) {
          let container = document.querySelector(
            ".account__information-subcontainer--" + i
          );
          container.style.transition = "none";
          setTimeout(() => {
            container.style.transition = "max-height .5s";
          }, 100);
        }
        this.toggleSubjectList(100);
      }
    },
    languageMenuRequested(to) {
      if (to === 2) {
        this.toggleSubjectList(1);
        this.$store.state.languageMenuRequested = 0;
      }
    }
  }
};
