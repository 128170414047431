<template>
  <div class="account__subcontainer">
    <div
      @click="toggleSubjectList(1)"
      class="account__information-subcontainer account__information-subcontainer--1"
    >
      <div class="account__information-key">
        {{ $t("account_settings")["language"]["key"] }}:
      </div>
      <!-- <div class="account__information">
        {{ getNativeLanguage($store.state.user.language) }}
      </div>
      <app-button
        type="button"
        :text="buttonText(1)"
        variation="transparent"
        class="account__change-button"
      ></app-button>
      <ul
        class="account__subject-list account__change-container account__change-container--1"
      >
        <p class="account__instruction">
          {{ $t("account_settings.language.instructions") }}
        </p>
        <li
          v-for="(lang, index) in languages"
          :key="lang"
          @click="
            selectLanguage(index);
            $event.stopPropagation();
          "
          class="account__subject"
        >
          {{ getNativeLanguage(languages[index]) }}
        </li>
      </ul> -->
    </div>

    <!-- <div @click="toggleSubjectList(1)" class="account__information-subcontainer account__information-subcontainer--1">
          <div class="account__information-key">
            Zahlungsart:
          </div>
          <div class="account__information">
            {{ $store.state.user.firstName }} {{ $store.state.user.lastName }}
            <span v-if="!$store.state.user.firstName && !$store.state.user.lastName">Unbekannt</span>
          </div>
          <app-button type="button" :text="buttonText(1)" variation="transparent" class="account__change-button"></app-button>
          <div class="account__change-container account__change-container--1">
            <app-input-complex :data="firstName" @click.native="$event.stopPropagation()" :inputText="$store.state.user.firstName" class="account__input account__input--1 account__input--first-name"></app-input-complex>
            <app-input-complex :data="lastName" @click.native="$event.stopPropagation()" :inputText="$store.state.user.lastName" class="account__input account__input--1 account__input--second account__input--last-name"></app-input-complex>
            <app-button type="submit" text="Speichern" variation="standard" @click.native="saveName(); $event.stopPropagation()" class="account__confirm-button"></app-button>
          </div>
        </div>
        <div @click="toggleSubjectList(2)" class="account__information-subcontainer account__information-subcontainer--2">
          <div class="account__information-key">
            Kartennummer:
          </div>
          <div class="account__information">
            {{ $store.state.user.email }}
          </div>
          <app-button type="button" :text="buttonText(2)" variation="transparent" class="account__change-button"></app-button>
          <div class="account__change-container account__change-container--2">
            <div class="account__information account__instructions">
              Trage links deine neue E-Mail-Adresse ein. Bestätige die Änderung mit deinem Passwort und speichere sie.
            </div>
            <app-input-complex :data="email" :eraseInput="eraseInput" @click.native="$event.stopPropagation()" class="account__input account__input--2 account__input--email"></app-input-complex>
            <app-input-password :data="password" :eraseInput="eraseInput" @click.native="$event.stopPropagation()" class="account__input account__input--2 account__input--second account__input--password account__input--old-password"></app-input-password>
            <app-button type="submit" text="Speichern" variation="standard" @click.native="saveEmail(); $event.stopPropagation()" class="account__confirm-button"></app-button>
          </div>
        </div>
        <div @click="toggleSubjectList(3)" class="account__information-subcontainer account__information-subcontainer--3">
          <div class="account__information-key">
            Gültig bis:
          </div>
          <div class="account__information">
            {{ bullets }}
          </div>
          <app-button type="button" :text="buttonText(3)" variation="transparent" class="account__change-button"></app-button>
          <div class="account__change-container account__change-container--3">
            <div class="account__information account__instructions">
              Trage links das alte Passwort ein und rechts das neue. Speichere dann die Änderung.
            </div>
            <app-input-password :data="passwordOld" :eraseInput="eraseInput" @click.native="$event.stopPropagation()" class="account__input account__input--2 account__input--old-password account__input--reset-old-password"></app-input-password>
            <app-input-password :data="passwordNew" :eraseInput="eraseInput" @click.native="$event.stopPropagation()" class="account__input account__input--2 account__input--second account__input--reset-new-password"></app-input-password>
            <app-button type="submit" text="Speichern" variation="standard" @click.native="savePassword(); $event.stopPropagation()" class="account__confirm-button"></app-button>
          </div>
        </div>
        <div @click="toggleSubjectList(4)" class="account__information-subcontainer account__information-subcontainer--4">
          <div class="account__information-key">
            Karteninhaber:
          </div>
          <div class="account__information">
            {{ $t('subjects.' + $store.state.user.subject) }}
          </div>
          <app-button type="button" :text="buttonText(4)" variation="transparent" class="account__change-button"></app-button>
          <ul class="account__subject-list account__change-container account__change-container--4">
            <p class="account__instruction">Wähle dein Studienfach:</p>
            <li v-for="(subj, index) in subjects" :key="subj" @click="$store.state.user.subject = subjects[index]; selectSubject(index); $event.stopPropagation()" class="account__subject">
                {{ $t('subjects.' + subjects[index]) }}
            </li>
          </ul>
        </div> -->
  </div>
</template>

<script>
import { accountSubcontainer } from "@/components/Organisms/AccountSubcontainer/_assets/_accountSubcontainer.script.js";

export default {
  mixins: [accountSubcontainer]
};
</script>

<style lang="scss" scoped>
@import "_assets/accountSubcontainer.style.scss";
</style>
