<template>
  <div class="account">
    <h2 class="account__heading">{{ accountHeading }}</h2>
    <div class="account__container">
      <ul class="account__menu">
        <li
          :class="{ 'account__menu-list-item--bold': shownSubcontainer === 1 }"
          @click="shownSubcontainer = 1"
        >
          {{ $t("account")["general"][0] }}
        </li>
        <!-- <li
          :class="{ 'account__menu-list-item--bold': shownSubcontainer === 2 }"
          @click="shownSubcontainer = 2"
        >
          {{ $t("account")["settings"][0] }}
        </li>
        <li
          :class="{ 'account__menu-list-item--bold': shownSubcontainer === 3 }"
          @click="shownSubcontainer = 3"
        >
          {{ $t("account")["payment"][0] }}
        </li>
        <li
          :class="{ 'account__menu-list-item--bold': shownSubcontainer === 4 }"
          @click="shownSubcontainer = 4"
        >
          {{ $t("account")["receipts"][0] }}
        </li>
        <li
          :class="{ 'account__menu-list-item--bold': shownSubcontainer === 5 }"
          @click="shownSubcontainer = 5"
        >
          {{ $t("account")["security"][0] }}
        </li>
        <li
          :class="{ 'account__menu-list-item--bold': shownSubcontainer === 6 }"
          @click="shownSubcontainer = 6"
        >
          {{ $t("account")["cookies"][0] }}
        </li> -->
      </ul>
      <!-- <app-account-subcontainer-1></app-account-subcontainer-1> -->
      <component
        :is="'app-account-subcontainer-' + shownSubcontainer"
        :subcontainer="shownSubcontainer"
        class="account__dynamic-component"
      ></component>
    </div>
    <app-footer class="account__footer"></app-footer>
  </div>
</template>

<script>
import AppAccountSubcontainer1 from "@/components/Organisms/AccountSubcontainer/AccountSubcontainer1.vue";
import AppAccountSubcontainer2 from "@/components/Organisms/AccountSubcontainer/AccountSubcontainer2.vue";
import AppAccountSubcontainer3 from "@/components/Organisms/AccountSubcontainer/AccountSubcontainer3.vue";
import AppAccountSubcontainer4 from "@/components/Organisms/AccountSubcontainer/AccountSubcontainer4.vue";
import AppAccountSubcontainer5 from "@/components/Organisms/AccountSubcontainer/AccountSubcontainer5.vue";
import AppAccountSubcontainer6 from "@/components/Organisms/AccountSubcontainer/AccountSubcontainer6.vue";
import AppFooter from "@/components/Templates/Footer.vue";

export default {
  name: "account",
  data() {
    return {
      shownSubcontainer: 1
    };
  },
  components: {
    AppAccountSubcontainer1,
    AppAccountSubcontainer2,
    AppAccountSubcontainer3,
    AppAccountSubcontainer4,
    AppAccountSubcontainer5,
    AppAccountSubcontainer6,
    AppFooter
  },
  computed: {
    accountHeading() {
      if (this.shownSubcontainer === 1) {
        return this.$t("account")["general"][1];
      } else if (this.shownSubcontainer === 2) {
        return this.$t("account")["settings"][1];
      } else if (this.shownSubcontainer === 3) {
        return this.$t("account")["payment"][1];
      } else if (this.shownSubcontainer === 4) {
        return this.$t("account")["receipts"][1];
      } else if (this.shownSubcontainer === 5) {
        return this.$t("account")["security"][1];
      } else {
        return this.$t("account")["cookies"][1];
      }
    },
    languageMenuRequested() {
      return this.$store.state.languageMenuRequested;
    }
  },
  methods: {
    requestLanguageMenu() {
      if (this.$store.state.languageMenuRequested === 1) {
        if (this.shownSubcontainer === 2) {
          this.$store.state.languageMenuRequested = 2;
        } else {
          this.shownSubcontainer = 2;
          setTimeout(() => {
            this.$store.state.languageMenuRequested = 2;
          }, 250);
        }
      }
    }
  },
  created() {
    this.requestLanguageMenu();
  },
  watch: {
    $route() {
      this.shownSubcontainer = 1;
    },
    languageMenuRequested() {
      this.requestLanguageMenu();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

/* ::v-deep label {
  display: none !important;
}

::v-deep .label {
  transform: translate(-1.25rem, 1.375rem) !important;

  &::after {
    left: 1.25rem;
    width: calc(100% + 1.375rem);
    background-color: $color-secondary !important;
  }
}

::v-deep input {
  padding-left: 1.375rem !important;
}

::v-deep .password-strength,
::v-deep .password-strength-text {
  display: none;
} */

.account {
  position: relative;
  width: 100%;
  min-height: calc(100vh + 6rem);
  padding: 6rem 0;
  // background-color: #101010;
  overflow-x: hidden;

  display: grid;
  grid-template-columns: 1fr $width-center 1fr;
  align-content: start;

  @include respond(tab-port) {
    // // grid-template-columns: 1fr $width-center-port 1fr;
  }

  &__heading {
    grid-column: 2 / 3;
    margin-left: 19.45rem;
    margin-top: 6rem;
    color: $color-white;
  }

  &__container {
    min-height: 80rem;
    grid-column: 2 / 3;
    margin-top: 2.75rem;
    border-radius: $border-radius;
    color: $color-white;

    // // background-color: green;

    overflow: hidden;

    display: grid;
    grid-template-columns: 15rem 1fr;
  }

  &__menu {
    font-size: 2.6rem;
    border-radius: $border-radius;
    color: #bbb;

    // background-color: red;
    // background-color: $color-black;

    & li {
      cursor: pointer;
    }

    & li:not(:last-child) {
      margin-bottom: 3.5rem;
    }
  }

  &__menu-list-item--bold {
    font-family: $font-primary-bold;
    color: $color-white;
  }

  &__footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: $color-black;
  }
}
</style>
