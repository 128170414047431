<template>
  <div class="account__subcontainer">
    <!-- <div
      @click="toggleSubjectList(1)"
      class="account__information-subcontainer account__information-subcontainer--1"
    >
      <div class="account__information-key">
        {{ $t("account_general")["name"]["key"] }}:
      </div>
      <div class="account__information">
        <span
          v-if="
            true ||
              (!$store.state.user.firstName && !$store.state.user.lastName)
          "
          >{{ $t("account_general")["name"]["default"] }}</span
        >
      </div>
      <app-button
        size="big"
        :text="buttonText()"
        class="account__change-button"
      ></app-button>
    </div> -->
    <div
      @click="toggleSubjectList(1)"
      class="account__information-subcontainer account__information-subcontainer--1"
    >
      <div class="account__information-key">
        Lizenz:
      </div>
      <div class="account__information">
        <!-- {{ $store.state.user.firstName }} {{ $store.state.user.lastName }} -->
        <span v-if="$store.state.license"
          >{{ licensesName }} vom {{ licensesStart }} bis zum
          {{ licensesEnd }}</span
        >
      </div>
      <!-- <app-button
        type="button"
        :text="buttonText(1)"
        variation="transparent"
        class="account__change-button"
      ></app-button> -->
      <!-- <div class="account__change-container account__change-container--1">
        <app-input-complex
          :data="firstName"
          @click="$event.stopPropagation()"
          :inputText="$store.state.user.firstName"
          class="account__input account__input--1 account__input--first-name"
        ></app-input-complex>
        <app-input-complex
          :data="lastName"
          @click="$event.stopPropagation()"
          :inputText="$store.state.user.lastName"
          class="account__input account__input--1 account__input--second account__input--last-name"
        ></app-input-complex>
        <app-button
          type="submit"
          :text="$t('account.controls.store')"
          variation="standard"
          @click="
            saveName();
            $event.stopPropagation();
          "
          class="account__confirm-button"
        ></app-button>
      </div> -->
    </div>
    <!-- <div
      @click="toggleSubjectList(2)"
      class="account__information-subcontainer account__information-subcontainer--2"
    >
      <div class="account__information-key">
        {{ $t("account_general.email.key") }}:
      </div>
      <div class="account__information">
        {{ $store.state.user.email }}
      </div>
      <app-button
        type="button"
        :text="buttonText(2)"
        variation="transparent"
        class="account__change-button"
      ></app-button>
      <div class="account__change-container account__change-container--2">
        <div class="account__information account__instructions">
          {{ $t("account_general.email.instructions") }}
        </div>
        <app-input-complex
          :data="email"
          :eraseInput="eraseInput"
          @click="$event.stopPropagation()"
          class="account__input account__input--2 account__input--email"
        ></app-input-complex>
        <app-input-password
          :data="password"
          :eraseInput="eraseInput"
          @click="$event.stopPropagation()"
          class="account__input account__input--2 account__input--second account__input--password account__input--old-password"
        ></app-input-password>
        <app-button
          type="submit"
          :text="$t('account.controls.store')"
          variation="standard"
          @click="
            saveEmail();
            $event.stopPropagation();
          "
          class="account__confirm-button"
        ></app-button>
      </div>
    </div>
    <div
      @click="toggleSubjectList(3)"
      class="account__information-subcontainer account__information-subcontainer--3"
    >
      <div class="account__information-key">
        {{ $t("account_general.password.key") }}:
      </div>
      <div class="account__information">
        {{ bullets }}
      </div>
      <app-button
        type="button"
        :text="buttonText(3)"
        variation="transparent"
        class="account__change-button"
      ></app-button>
      <div class="account__change-container account__change-container--3">
        <div class="account__information account__instructions">
          {{ $t("account_general.password.instructions") }}
        </div>
        <app-input-password
          :data="passwordOld"
          :eraseInput="eraseInput"
          @click="$event.stopPropagation()"
          class="account__input account__input--2 account__input--old-password account__input--reset-old-password"
        ></app-input-password>
        <app-input-password
          :data="passwordNew"
          :eraseInput="eraseInput"
          @click="$event.stopPropagation()"
          class="account__input account__input--2 account__input--second account__input--reset-new-password"
        ></app-input-password>
        <app-button
          type="submit"
          :text="$t('account.controls.store')"
          variation="standard"
          @click="
            savePassword();
            $event.stopPropagation();
          "
          class="account__confirm-button"
        ></app-button>
      </div>
    </div>
    <div
      @click="toggleSubjectList(4)"
      class="account__information-subcontainer account__information-subcontainer--4"
    >
      <div class="account__information-key">
        {{ $t("account_general.subject.key") }}:
      </div>
      <div class="account__information">
        {{ $t("subjects." + $store.state.user.subject) }}
      </div>
      <app-button
        type="button"
        :text="buttonText(4)"
        variation="transparent"
        class="account__change-button"
      ></app-button>
      <ul
        class="account__subject-list account__change-container account__change-container--4"
      >
        <p class="account__instruction">
          {{ $t("account_general.subject.instructions") }}
        </p>
        <li
          v-for="(subj, index) in subjects"
          :key="subj"
          @click="
            selectSubject(index);
            $event.stopPropagation();
          "
          class="account__subject"
        >
          {{ $t("subjects." + subjects[index]) }}
        </li>
      </ul>
    </div> -->
  </div>
</template>

<script>
import { accountSubcontainer } from "@/components/Organisms/AccountSubcontainer/_assets/_accountSubcontainer.script.js";

export default {
  mixins: [accountSubcontainer],

  computed: {
    licensesName() {
      if (
        this.$store.state.license in this.$store.state.licenses &&
        "name" in this.$store.state.licenses[this.$store.state.license]
      ) {
        return this.$store.state.licenses[this.$store.state.license].name;
      } else {
        return "";
      }
    },
    licensesStart() {
      if (
        this.$store.state.license in this.$store.state.licenses &&
        "start" in this.$store.state.licenses[this.$store.state.license]
      ) {
        return this.$store.state.licenses[this.$store.state.license].start;
      } else {
        return "";
      }
    },
    licensesEnd() {
      if (
        this.$store.state.license in this.$store.state.licenses &&
        "end" in this.$store.state.licenses[this.$store.state.license]
      ) {
        return this.$store.state.licenses[this.$store.state.license].end;
      } else {
        return "";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "_assets/accountSubcontainer.style.scss";
</style>
